/**
 *
 * @param {number} num
 * @param {number} decimalPlaces
 */
const round = (num, decimalPlaces = 0) => {
  const multiplier = Math.pow(10, decimalPlaces)
  return Math.round(num * multiplier) / multiplier
}

module.exports = round
