/**
 * Loosely validates email addresses
 * @param {*} val - Email address to validate
 * @returns {boolean} Returns true if valid or false if invalid
 */
const isValidEmail = val => {
  return /.+@.+\..+/.test(val)
}

module.exports = isValidEmail
