import { css } from 'styled-components'

const fonts = css`
  :root {
    /* Font family stack */
    --font-family: 'JD Sans', Arial, Helvetica, sans-serif;

    /* Font sizes */
    --font-size-h1: 3rem;
    --font-size-h2: 2.25rem;
    --font-size-h3: 2rem;
    --font-size-h4: 1.6875rem;
    --font-size-h5: 1.3125rem;
    --font-size-h6: 1.125rem;

    /* Font weights */
    --font-weight-h1: 600;
    --font-weight-h2: 600;
    --font-weight-h3: 600;
    --font-weight-h4: 600;
    --font-weight-h5: 600;
    --font-weight-h6: 700;
  }

  .mobile-font-size-h2 {
    font-size: var(--font-size-h2);
  }

  .mobile-font-size-h3 {
    font-size: var(--font-size-h3);
  }

  .mobile-font-size-h4 {
    font-size: var(--font-size-h4);
  }

  .mobile-font-size-h5 {
    font-size: var(--font-size-h5);
  }

  .mobile-font-size-h6 {
    font-size: var(--font-size-h6);
  }
`

export default fonts
