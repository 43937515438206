import { createGlobalStyle } from 'styled-components'
import reset from './reset'
import fonts from './fonts'
import sizes from './sizes'
import colors from './colors'

const global = createGlobalStyle`
  ${reset}
  ${fonts}
  ${sizes}
  ${colors}

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    background: var(--color-n10);
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 16px;
    overflow-y: hidden;
  }

  body {
    color: var(--color-n900);
    font-size: 1rem;
    text-rendering: optimizeLegibility;
    width: 100%;
  }

  html,
  body {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  h1 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-h1);
    margin: 0;
  }

  h2 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-h2);
    margin: 0;
  }

  h3 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-h3);
    margin: 0;
  }

  h4 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-h4);
    margin: 0;
  }

  h5 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-h5);
    margin: 0;
  }

  h6 {
    color: var(--color-n900);
    font-family: inherit;
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-h6);
    margin: 0;
  }

  p {
    color: var(--color-n700);
    font-size: 1.125rem;
    line-height: 1.35;

    a {
      color: var(--color-g400);
    }
  }

  small {
    font-size: 0.75rem;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
    font-size: 1.125rem;
    line-height: 1.35;

    > li {
      color: var(--color-n700);
      margin: 0 0 5px;
    }

    > li:last-child {
      margin: 0;
    }
  }


  ::selection {
    background-color: var(--color-g400);
    color: #fff;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }

  .hidden {
    display: none !important;
  }

  @media print {
    html {
      background: #fff;
    }

    .desktop-hidden, .print-hidden {
      display: none !important;
    }
  }

  @media screen and (min-width: 900px) {
    .desktop-hidden {
      display: none !important;
    }
  }

  @media screen and (max-width: 899px) {
    html {
      overflow-y: visible;
    }
    
    .mobile-hidden {
      display: none !important;
    }
  }
`

export default global
