/**
 * Valide a phone number
 * @param {*} val - Phone number to validate
 * @returns {boolean} Returns true if valid or false if invalid
 */
const isValidPhone = val => {
  // Regex borrowed from http://stackoverflow.com/a/16702965/1378668
  return /^(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?$/g.test(val)
}

module.exports = isValidPhone
