import { css } from 'styled-components'

const clearfix = css`
  :after {
    clear: both;
    content: '';
    display: block;
    visibility: hidden;
  }
`

export default clearfix
