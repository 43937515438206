/**
 * Valide a zip code
 * @param {*} val - Zip code to validate
 * @returns {boolean} Returns true if valid or false if invalid
 */
const isValidZip = val => {
  return /^\d{5}$/.test(val)
}

module.exports = isValidZip
