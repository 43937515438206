const meta = {
  analyticsAppName: 'configure',
  description:
    'Build and price a John Deere to meet your needs. Customize your build with options, attachments, implements, warranty, and financing and easily calculate the costs.',
  image: '/hutson-inc-og.jpg',
  siteUrl: 'https://build.hutsoninc.com',
  title: 'Build Your Own | Huston Inc',
  twitterUsername: '@Hutson_Inc',
}

module.exports = meta
