const overrides = [['gator', 'Gator']]

const getOverride = val => {
  const overridesMatch = overrides.find(override => override[0] === val)
  return overridesMatch ? overridesMatch[1] : val
}

const lowercase = val => {
  const valArr = val.toLowerCase().split(' ')
  return valArr.map(getOverride).join(' ')
}

module.exports = lowercase
