import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const sizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']

const Spacer = ({ className, size, variable, ...props }) => {
  return (
    <StyledSpacer className={`spacer ${className}`} size={size} variable={variable} {...props} />
  )
}

Spacer.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(sizes).isRequired,
  variable: PropTypes.bool.isRequired,
}

Spacer.defaultProps = {
  className: '',
  size: 'm',
  variable: true,
}

const getNextSmallestSize = size => {
  const currentSizeIndex = sizes.findIndex(str => str === size)
  if (currentSizeIndex === 0) {
    return size
  }
  return sizes[currentSizeIndex - 1]
}

const StyledSpacer = styled.div`
  margin-top: ${props =>
    `var(--size-${props.variable ? getNextSmallestSize(props.size) : props.size})`};

  @media (min-width: 900px) {
    margin-top: ${props => `var(--size-${[props.size]})`};
  }
`

export default Spacer
