import { css } from 'styled-components'

const colors = css`
  :root {
    /* Neutrals */
    --color-black: #000000;
    --color-n900: #080909;
    --color-n800: #111212;
    --color-n700: #222324;
    --color-n600: #323536;
    --color-n500: #434648;
    --color-n400: #54585a;
    --color-n300: #616567;
    --color-n200: #6f7375;
    --color-n100: #7d8081;
    --color-n90: #8b8e8f;
    --color-n80: #989b9c;
    --color-n70: #a6a8a9;
    --color-n60: #b4b6b7;
    --color-n50: #c1c3c3;
    --color-n40: #cfd1d1;
    --color-n30: #dddede;
    --color-n20: #eaebeb;
    --color-n10: #f8f8f9;
    --color-white: #ffffff;

    /* Greens */
    --color-g700: #162f17;
    --color-g600: #214622;
    --color-g500: #2c5e2e;
    --color-g400: #377539;
    --color-g300: #5f9161;
    --color-g200: #87ac88;
    --color-g100: #afc8b0;
    --color-g50: #d7e3d7;

    /* Yellows */
    --color-y700: #665900;
    --color-y600: #998500;
    --color-y500: #ccb200;
    --color-y400: #ffde00;
    --color-y300: #ffeb66;
    --color-y200: #fff299;
    --color-y100: #fff8cc;
    --color-y50: #fffce5;

    /* Reds */
    --color-r500: #b00000;
    --color-r400: #dc0000;
    --color-r300: #e33333;
    --color-r200: #ea6666;
    --color-r100: #f19999;
    --color-r50: #f8cccc;
  }
`

export default colors
