const React = require('react')
const ErrorBoundary = require('components/error-boundary').default
const { gtag } = require('@hutson/utils')
const siteMetadata = require('./src/site-metadata')

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: false,
    })
  }
  if (process.env.NODE_ENV === 'production') {
    gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
      app_name: siteMetadata.analyticsAppName,
      currency: 'USD',
      linker: {
        domains: ['hutsoninc.com', 'build.hutsoninc.com', 'fieldguide.hutsoninc.com'],
      },
      send_page_view: false,
    })
  }
}

exports.wrapPageElement = ({ element }) => {
  return <ErrorBoundary>{element}</ErrorBoundary>
}
