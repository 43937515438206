module.exports = [{
      plugin: require('../node_modules/@hutsoninc/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"742806786717697"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8DFF9Z5EJH"],"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","scope":"html","custom":[{"name":["JD Sans"],"file":"/fonts/jd-sans-font.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hutson Inc Build & Price","short_name":"Hutson Inc Build & Price","start_url":"/","background_color":"#f8f8f9","theme_color":"#000","display":"browser","icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"814ae67eba2d04a3c6129a7ccbb8c1e0"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://a18e6683a2874fe297f22244b488d04e@o562528.ingest.sentry.io/5701181"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
