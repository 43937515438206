/**
 * Sort an array of objects by a property on the objects
 * @param {{}[]} arr - Array of objects to sort
 * @param {string} key - Property to sort by
 * @param {ASC|DESC} direction - Direction to sort
 */
const sortBy = (arr, key, direction = 'ASC') => {
  if (Array.isArray(arr)) {
    arr.sort((a, b) => {
      if (a[key] > b[key]) return direction === 'DESC' ? -1 : 1
      if (a[key] < b[key]) return direction === 'DESC' ? 1 : -1
      return 0
    })
  }
  return arr
}

module.exports = sortBy
