import { css } from 'styled-components'

const sizes = css`
  :root {
    --size-xxs: 4px;
    --size-xs: 8px;
    --size-s: 16px;
    --size-m: 24px;
    --size-l: 36px;
    --size-xl: 48px;
    --size-xxl: 64px;
  }
`

export default sizes
