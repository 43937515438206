import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = ({ children, color, ghost, ...props }) => {
  return (
    <StyledButton color={color} ghost={ghost} {...props}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['green', 'gray', 'yellow']),
  ghost: PropTypes.bool,
}

Button.defaultProps = {
  color: 'green',
  ghost: false,
}

const StyledButton = styled.button`
  background-color: ${props => {
    if (props.ghost) {
      return 'transparent'
    }
    switch (props.color) {
      case 'yellow':
        return 'var(--color-y400)'
      case 'gray':
        return 'var(--color-n100)'
      case 'green':
      default:
        return 'var(--color-g400)'
    }
  }};
  border: ${props => {
    switch (props.color) {
      case 'yellow':
        return '2px solid var(--color-y400)'
      case 'gray':
        return '2px solid var(--color-n70)'
      case 'green':
      default:
        return '2px solid var(--color-g400)'
    }
  }};
  color: ${props => {
    switch (props.color) {
      case 'yellow':
        return props.ghost ? 'var(--color-y400)' : 'var(--color-y700)'
      case 'gray':
        return props.ghost ? 'var(--color-n100)' : 'var(--color-n700)'
      case 'green':
      default:
        return props.ghost ? 'var(--color-g400)' : '#fff'
    }
  }};
  cursor: default;
  display: block;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.15;
  margin: 0;
  padding: var(--size-s);
  overflow: visible;
  text-align: center;
  text-transform: none;
  transition: all 0.2s ease;
  width: auto;

  :disabled {
    opacity: 0.5;
  }

  :not(:disabled) {
    :hover,
    :active,
    :focus {
      background-color: ${props => {
        switch (props.color) {
          case 'yellow':
            return props.ghost ? 'var(--color-y400)' : 'var(--color-y300)'
          case 'gray':
            return props.ghost ? 'var(--color-n70)' : 'var(--color-n50)'
          case 'green':
          default:
            return props.ghost ? 'var(--color-g400)' : 'var(--color-g500)'
        }
      }};
      border: ${props => {
        switch (props.color) {
          case 'yellow':
            return props.ghost ? '2px solid var(--color-y400)' : '2px solid var(--color-y300)'
          case 'gray':
            return props.ghost ? '2px solid var(--color-n70)' : '2px solid var(--color-n50)'
          case 'green':
          default:
            return props.ghost ? '2px solid var(--color-g400)' : '2px solid var(--color-g500)'
        }
      }};
      color: ${props => {
        switch (props.color) {
          case 'yellow':
            return 'var(--color-y700)'
          case 'gray':
            return 'var(--color-n700)'
          case 'green':
          default:
            return '#fff'
        }
      }};
      cursor: pointer;
    }
  }

  @media screen and (max-width: 899px) {
    font-size: 0.875rem;
    padding: var(--size-s) var(--size-xs);
  }

  @media print {
    display: none !important;
  }
`

export default Button
