const isDefined = require('./is-defined')

/**
 * Removes empty keys (null or undefined) from an object (shallow)
 * @param {Object} obj
 * @returns {Object}
 */
const removeEmptyKeys = obj => {
  const newObj = {}

  for (let key in obj) {
    if (isDefined(obj[key])) {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

module.exports = removeEmptyKeys
