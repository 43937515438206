import React from 'react'
import styled from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import { Link } from 'gatsby'
import { global as GlobalStyle } from 'styles'
import Button from 'components/button'
import Spacer from 'components/spacer'
import { noop } from 'utils'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Fallback = ({ error, resetError = noop }) => {
  console.error(error.message)
  return (
    <>
      <GlobalStyle />
      <Container role='alert'>
        <h2>Something went wrong</h2>
        <p className='description'>
          Sorry! Looks like something broke unexpectedly. Don't worry, we've captured an error
          report and we'll work on fixing the issue right away! If you need assistance, please{' '}
          <OutboundLink href='https://www.hutsoninc.com/contact/' onClick={resetError}>
            submit a contact form
          </OutboundLink>
          .
        </p>
        <Spacer size='xs' />
        <Button as={Link} className='button' to='/' onClick={resetError} ghost>
          Go back to homepage
        </Button>
      </Container>
    </>
  )
}

const Container = styled.div`
  align-content: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  height: 100vh;
  justify-content: center;
  text-align: center;
  width: 100vw;

  .description {
    margin-left: auto;
    margin-right: auto;
    width: 80ch;
  }

  .button {
    display: inline-block;
    margin: 0 auto;
    text-decoration: none;
  }
`

const ErrorBoundaryContainer = ({ children }) => {
  return <ErrorBoundary fallback={Fallback}>{children}</ErrorBoundary>
}

export default ErrorBoundaryContainer
