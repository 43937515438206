const formatGutter = gutter => {
  if (gutter !== undefined) {
    if (typeof gutter !== 'number') {
      gutter = parseInt(gutter)
    }
  } else {
    gutter = 24
  }
  return gutter
}

const calculateColumns = columns => {
  columns = columns.split('/').map(val => Number(val))

  return columns[0] / columns[1]
}

const getWidth = (columns, gutter) => {
  gutter = formatGutter(gutter)
  columns = calculateColumns(columns)

  return `calc(${(0.999 * columns * 100).toFixed(2)}% - ${gutter - gutter * columns}px)`
}

const column = (columns, gutter) => {
  if (typeof columns !== 'string') {
    throw new Error('columns must be a string')
  }
  const nthChild = columns.split('/')[1]

  const marginRight = formatGutter(gutter)
  const width = getWidth(columns, gutter)

  return `
        float: left;
        margin-right: ${marginRight}px;
        width: ${width};

        :nth-child(1n) {
            clear: none;
            margin-right: ${marginRight}px;
        }

        :nth-child(${nthChild}n+1) {
            clear: both;
        }

        :nth-child(${nthChild}n),
        :last-child {
            margin-right: 0;
        }
    `
}

export default column
